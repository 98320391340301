/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import * as React from 'react';
import { Button, audiDarkTheme, audiLightTheme, ThemeProvider } from '@audi/audi-ui-react';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import Slider from 'react-slick';
import { FeatureGalleryTextBlock } from '../feature-gallery-text-block/index';
import WLTPComponent from '../MediaDisclaimerAndWLTP';
import { LeftArrow } from '../icons/LeftArrow';
import { RightArrow } from '../icons/RightArrow';
import {
  CarouselContainer,
  ContentContainer,
  FlexContainer,
  SliderWrap,
  SlideText,
  SlickSlider,
  Image,
  ImageContainer,
  TextBlockSlider,
  TextBlockContent,
} from './style';
import { constants } from '../../../constants';
import { TrackingHelperWrapper } from '../../utilTrackingHelpers/TrackingHelperWrapper';
import { FeatureGalleryVideo } from '../feature-gallery-video';

interface FeatureGalleryProps {
  // eslint-disable-next-line
  content?: any;
  localeService?: LocaleServiceV1;
  // eslint-disable-next-line
  updateDatalayer: Function;
  trackingWrapper: TrackingHelperWrapper;
  // eslint-disable-next-line
  handleClick: Function;
  // eslint-disable-next-line
  trackExitLink: Function;
  gallery: string[];
  theme: string;
}
// eslint-disable-next-line

// eslint-disable-next-line
const FeatureGallery: React.FC<FeatureGalleryProps> = (props) => {
  const [nav1, setNav1] = React.useState<Slider | null>(null);
  const [nav2, setNav2] = React.useState<Slider | null>(null);
  const [lastNumberClicked, setLastNumberClicked] = React.useState<number | null>(null);
  // eslint-disable-next-line
  const slider1 = React.useRef<Slider | null>(null);
  const slider2 = React.useRef<Slider | null>(null);
  const ref = React.useRef(null);
  const [video, setVideo] = React.useState<HTMLVideoElement | null>(null);

  // eslint-disable-next-line
  const divRefSlide1 = React.useRef<HTMLDivElement | null>(null!);
  const divRefSlide2 = React.useRef<HTMLDivElement | null>(null);
  const fgData = props;

  const { trackingWrapper, updateDatalayer, gallery, theme } = fgData;

  const slidesCount = gallery ? gallery.length - 1 : 0;
  const [isCurrent, setIsCurrent] = React.useState<number>(1);

  React.useEffect(() => {
    if (ref.current && trackingWrapper) {
      trackingWrapper?.getTrackingServiceHelper().trackImpressionEvent(ref, updateDatalayer);
    }
  }, [ref, trackingWrapper]);
  // eslint-disable-next-line
  const NextArrow = (nextArrowProps: any) => {
    const { className, onClick } = nextArrowProps;

    return (
      <div
        className={className}
        aria-label="Go to the next slide"
        data-testid="carouselNextArrow"
        id="fg-next-arrow"
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        <RightArrow theme={theme} />
      </div>
    );
  };
  // eslint-disable-next-line
  const PrevArrow = (prevArrowProps: any) => {
    const { className, onClick } = prevArrowProps;

    return (
      <div
        className={className}
        aria-label="Go to the previous slide"
        data-testid="carouselPrevArrow"
        id="fg-prev-arrow"
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        <LeftArrow theme={theme} />
      </div>
    );
  };
  React.useEffect(() => {
    // eslint-disable-next-line
    setNav1(slider1.current!);
    // eslint-disable-next-line
    setNav2(slider2.current!);
    indexTabHandler();
    setActiveVideo(0);
  }, [slider1, slider2]);

  const setActiveVideo = (current: number) => {
    const { asset } = gallery[current] as any;
    const { autoPlay } = asset;
    const currentVideo: HTMLVideoElement | undefined | null = divRefSlide1?.current?.querySelector(
      `div.slick-slide.slick-active.slick-current  video`,
    );

    if (currentVideo) {
      currentVideo.autoplay = autoPlay;
      setVideo(currentVideo);
    }
  };

  const indexTabHandler = () => {
    // eslint-disable-next-line
    const activeSlide = divRefSlide1?.current?.querySelectorAll('.slick-slide')!;
    activeSlide?.forEach((slide) => {
      if (slide.classList.contains('slick-active')) {
        slide.querySelectorAll('button').forEach((button) => (button.tabIndex = 0));
      } else if (!slide.classList.contains('slick-active')) {
        slide.querySelectorAll('button').forEach((button) => (button.tabIndex = -1));
      }
    });
    textBlockTabHandler();
  };
  const textBlockTabHandler = () => {
    // eslint-disable-next-line
    const activeSlide = divRefSlide2?.current?.querySelectorAll('.slick-slide')!;

    activeSlide?.forEach((slide) => {
      if (slide.classList.contains('slick-active')) {
        slide.querySelectorAll('a').forEach((a) => (a.tabIndex = 0));
      } else if (!slide.classList.contains('slick-active')) {
        slide.querySelectorAll('a').forEach((a) => (a.tabIndex = -1));
      }
    });
  };

  const getPaginationWidth = () => {
    const width = divRefSlide2?.current?.querySelector('ul')?.offsetWidth;

    return width;
  };
  const settings1 = {
    accessibility: true,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    beforeChange: (current: number) => {
      indexTabHandler();
      setActiveVideo(current);
    },
    afterChange: (current: number) => {
      indexTabHandler();
      setActiveVideo(current);
    },
  };

  const settings2 = {
    accessibility: true,
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    adaptiveHeight: true,
    beforeChange: (current: number) => {
      indexTabHandler();
      setActiveVideo(current);
    },
    afterChange: (current: number) => {
      indexTabHandler();
      setActiveVideo(current);
    },

    // eslint-disable-next-line
    customPaging: (
      // eslint-disable-next-line
      i: number,
    ) => {
      return (
        // eslint-disable-next-line
        <React.Fragment>
          <SlideText data-testid="carouselPaginationLink" key="carouselPaginationLink">
            {i === 0 && (
              <FlexContainer>
                <Button
                  className="prev"
                  variant="text"
                  aria-label="Previous slide"
                  onClick={(e) => {
                    slider1.current?.slickPrev();
                    const id: number = e.currentTarget.textContent;
                    // eslint-disable-next-line
                    props.handleClick(id);
                    // eslint-disable-next-line
                    isCurrent == 1 ? setIsCurrent(slidesCount + 1) : setIsCurrent(isCurrent - 1);
                  }}
                >
                  <PrevArrow />
                </Button>
                <Button
                  variant="text"
                  id={`pagination${i + 1}`}
                  aria-label={
                    isCurrent === i + 1 ? `Slide ${i + 1}, current` : `Go to slide ${i + 1}`
                  }
                  className="goTo"
                  onClick={(e) => {
                    slider1.current?.slickGoTo(i);
                    const id: number = e.currentTarget.textContent;
                    if (!lastNumberClicked || String(lastNumberClicked) !== String(id)) {
                      // eslint-disable-next-line
                      props.handleClick(id);
                    }
                    setIsCurrent(id);
                    setLastNumberClicked(id);
                  }}
                >
                  {i + 1}
                </Button>
              </FlexContainer>
            )}

            {i > 0 && i < slidesCount && (
              <Button
                variant="text"
                id={`pagination${i + 1}`}
                aria-label={
                  isCurrent === i + 1 ? `Slide ${i + 1}, current` : `Go to slide ${i + 1}`
                }
                className="goTo"
                onClick={(e) => {
                  slider1.current?.slickGoTo(i);
                  // eslint-disable-next-line
                  const id: number = e.currentTarget.textContent;
                  if (!lastNumberClicked || String(lastNumberClicked) !== String(id)) {
                    // eslint-disable-next-line
                    props.handleClick(id);
                  }
                  setIsCurrent(id);
                  setLastNumberClicked(id);
                }}
              >
                {i + 1}
              </Button>
            )}

            {i === slidesCount && (
              <FlexContainer>
                <Button
                  variant="text"
                  id={`pagination${i + 1}`}
                  aria-label={
                    isCurrent === i + 1 ? `Slide ${i + 1}, current` : `Go to slide ${i + 1}`
                  }
                  className="goTo"
                  onClick={(e) => {
                    slider1.current?.slickGoTo(i);
                    const id: number = e.currentTarget.textContent;
                    if (!lastNumberClicked || String(lastNumberClicked) !== String(id)) {
                      // eslint-disable-next-line
                      props.handleClick(id);
                    }
                    setIsCurrent(id);
                    setLastNumberClicked(id);
                  }}
                >
                  {i + 1}
                </Button>

                <Button
                  variant="text"
                  className="next"
                  aria-label="Next slide"
                  onClick={(e) => {
                    slider1.current?.slickNext();
                    const id = e.currentTarget.textContent;
                    // eslint-disable-next-line
                    props.handleClick(id);
                    // eslint-disable-next-line
                    isCurrent === slidesCount + 1 ? setIsCurrent(1) : setIsCurrent(isCurrent + 1);
                  }}
                >
                  <NextArrow />
                </Button>
              </FlexContainer>
            )}
          </SlideText>
        </React.Fragment>
      );
    },
  };
  return (
    <SlickSlider
      color="primary"
      data-test-id="impression-tracking-box"
      id="impression-tracking-box"
      ref={ref}
    >
      <ThemeProvider theme={theme === constants.theme.darkTheme ? audiDarkTheme : audiLightTheme}>
        <CarouselContainer theme={theme}>
          <SliderWrap ref={divRefSlide1}>
            <Slider
              {...settings1}
              // eslint-disable-next-line
              asNavFor={nav2!}
              // eslint-disable-next-line
              ref={(slider: Slider) => (slider1.current = slider)}
            >
              {gallery?.map(
                (
                  // eslint-disable-next-line
                  element: any,
                  index: number,
                ) => (
                  <ContentContainer
                    data-testid="carouselItem"
                    // eslint-disable-next-line
                    key={index}
                    className="main-container"
                  >
                    {element?.asset?.assetType === constants?.assetType?.image ? (
                      <ImageContainer className="image" theme={theme}>
                        <picture>
                          <source
                            srcSet={`${element?.asset?.imageUrlSmallViewport.assetValue}?width=375`}
                            media={`(max-width:${constants.displaySizes.mobile})`}
                          />
                          <source
                            srcSet={`${element?.asset?.imageUrlLargeViewport.assetValue}?width=1920`}
                            media={`(min-width:${constants.displaySizes.tablet})`}
                          />
                          <Image
                            src={`${element?.asset?.imageUrlSmallViewport.assetValue}?width=768`}
                            alt={element?.asset?.alternateDescription}
                            data-testid="carouselItemImage"
                            className="img"
                          />
                        </picture>
                      </ImageContainer>
                    ) : (
                      <FeatureGalleryVideo
                        alternateDescription={element?.asset?.alternateDescription}
                        autoPlay={element?.asset?.autoPlay}
                        loop={element?.asset?.loop}
                        closedCaption={element?.asset?.closedCaption}
                        theme={theme}
                        videoHasAudio={element?.asset?.videoHasAudio}
                        videoUrlLargeScreen={element?.asset?.videoUrlLargeViewport.assetValue}
                        videoUrlSmallScreen={element?.asset?.videoUrlSmallViewport.assetValue}
                        index={index}
                        currentVideo={video}
                        handleClick={props.handleClick}
                      />
                    )}
                  </ContentContainer>
                ),
              )}
            </Slider>
          </SliderWrap>
          <TextBlockSlider
            data-testid="content-block"
            theme={theme}
            ref={divRefSlide2}
            width={getPaginationWidth()}
          >
            <Slider
              {...settings2}
              // eslint-disable-next-line
              asNavFor={nav1!}
              ref={(slider: Slider) => (slider2.current = slider)}
            >
              {gallery?.map(
                (
                  // eslint-disable-next-line
                  element: any,
                  // eslint-disable-next-line
                  index: number,
                ) => (
                  // eslint-disable-next-line
                  // eslint-disable-next-line
                  <React.Fragment key={index}>
                    <TextBlockContent data-testid="carouselItemDescription" className="ctas">
                      <FeatureGalleryTextBlock
                        ctaLink={element?.cta1 ? element.cta1.ctaLink1 : null}
                        ctaText={element?.cta1 ? element.cta1.ctaText1 : null}
                        ariaLabel={element?.cta1 ? element.cta1.ctaAria1 : null}
                        title={element.title}
                        bodyText={element.bodyText}
                        theme={theme}
                        openIn={element.cta1 ? element?.cta1?.openIn : null}
                        width={getPaginationWidth()}
                        // eslint-disable-next-line
                        trackExitLink={props.trackExitLink}
                        // eslint-disable-next-line
                        handleClick={props.handleClick}
                      />
                    </TextBlockContent>

                    {element.legalText?.mediaDisclaimer || element.legalText?.wltpKey ? (
                      <WLTPComponent
                        theme={theme}
                        mediaDisclaimer={element.legalText?.mediaDisclaimer}
                        wltpTextValues={element.legalText?.wltpKey}
                      />
                    ) : null}
                  </React.Fragment>
                ),
              )}
            </Slider>
          </TextBlockSlider>
        </CarouselContainer>
      </ThemeProvider>
    </SlickSlider>
  );
};

export default FeatureGallery;
