import React, { useContext } from 'react';
import { TrackingHelperWrapper } from '../../utilTrackingHelpers/TrackingHelperWrapper';
import FeatureGallery from '../feature-gallery-carousel';
import { ContentContext } from '../../contexts';

import mockContent from '../../content.json';

interface ClickEventProps {
  // eslint-disable-next-line
  updateDatalayer: Function;
  trackingWrapper: TrackingHelperWrapper;
}

const ClickEvent = ({ updateDatalayer, trackingWrapper }: ClickEventProps) => {
  const context = useContext(ContentContext);

  const handleClick = (id: string) => {
    trackingWrapper?.getTrackingServiceHelper().sendClickEvent(id);
    updateDatalayer();
  };

  const trackExitLink = (component: string, url: string) =>
    trackingWrapper?.getTrackingServiceHelper().sendExitLinkEvent(component, url);

  return (
    <div>
      <FeatureGallery
        {...(context?.content ?? mockContent)}
        updateDatalayer={updateDatalayer}
        trackingWrapper={trackingWrapper}
        trackExitLink={trackExitLink}
        handleClick={handleClick}
      />
    </div>
  );
};

export default ClickEvent;
