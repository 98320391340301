// eslint-disable-next-line
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
// eslint-disable-next-line
import React from 'react';
import { audiLightTheme, audiDarkTheme, Button, Text, ThemeProvider } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import { TextBlock, TitleTextBlock, CTAContainer } from './style';
import { constants } from '../../../constants';

interface TextBlockProps {
  ctaLink: string;
  ctaText: string;
  title: string;
  bodyText: string;
  theme: string;
  openIn: string;
  width?: number | undefined;
  ariaLabel?: string;
  // eslint-disable-next-line
  trackExitLink: Function;
  // eslint-disable-next-line
  handleClick: Function;
}
// eslint-disable-next-line
export const FeatureGalleryTextBlock: React.FC<TextBlockProps> = (props) => {
  const { bodyText, ctaLink, ctaText, title, theme, openIn, ariaLabel, width } = props;

  const themeProvider = () => {
    const { darkTheme } = constants.theme;
    if (theme === darkTheme) {
      return audiDarkTheme;
    }
    return audiLightTheme;
  };

  function setCtaUrl(link: string) {
    if (!(link.indexOf('/') === 0)) {
      return link;
    }
    const url = typeof window !== 'undefined' && window.location.origin;
    return typeof window !== 'undefined' ? url + link : undefined;
  }

  return (
    <ThemeProvider theme={themeProvider()}>
      <TextBlock theme={theme} className="fg-text-block-content">
        <TitleTextBlock width={width}>
          <Text as="p" variant="copy1">
            {' '}
            <Text as="strong" variant="copy1" weight="bold">
              {renderTextWithFootnotesReferences(title)}
            </Text>{' '}
            {renderTextWithFootnotesReferences(bodyText)}
          </Text>
        </TitleTextBlock>
        {ctaText ? (
          <CTAContainer>
            <Button
              variant="text"
              stretch
              newWindow={openIn !== 'Same tab'}
              href={setCtaUrl(ctaLink)}
              aria-label={ariaLabel}
              onClick={() => {
                // eslint-disable-next-line
                props.trackExitLink(ctaText, ctaLink);
              }}
            >
              {ctaText}
            </Button>
          </CTAContainer>
        ) : null}
      </TextBlock>
    </ThemeProvider>
  );
};
