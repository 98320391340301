import React from 'react';
import { constants } from '../../../constants';

type ClosedCaptionProps = {
  theme: string;
};

// eslint-disable-next-line
export const ClosedCaptionOff: React.FC<ClosedCaptionProps> = ({ theme }) => {
  const { black, white } = constants.color;
  const { lightTheme } = constants.theme;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 6.25C23 5.26193 22.2381 4.5 21.25 4.5H1V17.25C1 18.2381 1.76193 19 2.75 19H23V6.25ZM1.5 17.25V5H21.25C21.9619 5 22.5 5.53807 22.5 6.25V18.5H2.75C2.03807 18.5 1.5 17.9619 1.5 17.25Z"
      />
      <title>system-cc-off-large</title>

      <mask
        id="mask0"
        // eslint-disable-next-line
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="1"
        y="4"
        width="22"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.25 4.5C22.2381 4.5 23 5.26193 23 6.25V19H2.75C1.76193 19 1 18.2381 1 17.25V4.5H21.25ZM1.5 5V17.25C1.5 17.9619 2.03807 18.5 2.75 18.5H22.5V6.25C22.5 5.53807 21.9619 5 21.25 5H1.5ZM11.2485 14.26C10.6817 14.5029 10.0224 14.7111 8.8311 14.7111C6.7376 14.7111 5.5 13.4735 5.5 11.5998C5.5 9.83013 6.64507 8.5 9.03929 8.5C9.83737 8.5 10.6586 8.65036 11.1559 8.87012V9.80699C10.6354 9.5988 9.8605 9.42531 9.01616 9.42531C7.36218 9.42531 6.67976 10.3043 6.67976 11.5998C6.67976 13.0224 7.57037 13.7858 8.94676 13.7858C9.9993 13.7858 10.7395 13.5313 11.2485 13.3V14.26ZM18.5 14.26C17.9333 14.5029 17.274 14.7111 16.0826 14.7111C13.9891 14.7111 12.7515 13.4735 12.7515 11.5998C12.7515 9.83013 13.8966 8.5 16.2908 8.5C17.0889 8.5 17.9101 8.65036 18.4075 8.87012V9.80699C17.887 9.5988 17.112 9.42531 16.2677 9.42531C14.6137 9.42531 13.9313 10.3043 13.9313 11.5998C13.9313 13.0224 14.8219 13.7858 16.1983 13.7858C17.2508 13.7858 17.9911 13.5313 18.5 13.3V14.26Z"
          fill={theme === lightTheme ? black : white}
        />
      </mask>
      <g mask="url(#mask0)">
        <rect width="24" height="24" fill="white" />
      </g>
    </svg>
  );
};

export default ClosedCaptionOff;
