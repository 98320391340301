import styled from 'styled-components';
import { constants } from '../../../constants';

interface WLTPContainerProps {
  theme: string;
}

const device = {
  desktop: `(min-width: ${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width: ${constants.displaySizes.largeDesktop})`,
  mobile: `(min-width: ${constants.displaySizes.mobile})`,
  smallDesktop: `(min-width: ${constants.displaySizes.smallDesktop})`,
  tablet: `(min-width: ${constants.displaySizes.tablet})`,
  smallTablet: `(max-width: ${constants.displaySizes.tablet})`,
};

export const WLTPContainer = styled.div<WLTPContainerProps>`
  background-color: ${(props) =>
    props.theme === constants.theme.darkTheme ? constants.color.black : constants.color.white};
  padding: 24px 0;

  .fg-wltp-items {
    margin: 0 5px 0 0;
  }

  @media ${device.mobile} {
    p {
      color: ${(props) =>
        props.theme === constants.theme.darkTheme ? constants.color.white : constants.color.black};
    }
  }
`;
export const ConsumptionContainer = styled.div`
  margin-bottom: 8px;
  min-height: 50px;
  width: 100%;

  p span sup a {
    font-size: 16px !important;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0px;
    min-height: 25px;
  }
`;
export const EmissionContainer = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;
export const MediaDisclaimerContainer = styled.div`
  width: 100%;
  p sup a {
    font-size: 16px !important;
  }
`;
