/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { EditorJsonContent, FalconContent } from './ContentInterfaces';

const normalizeContent = (content: EditorJsonContent | FalconContent): EditorJsonContent => {
  if (content?.__type !== 'aem-headless') {
    return { ...content };
  }

  return convertHeadlessContentToEditorFormat(content);
};

const convertHeadlessContentToEditorFormat = (content: FalconContent): EditorJsonContent => {
  const { fields } = content;

  // eslint-disable-next-line
  const itemsFromGallery: any = (fields?.gallery as any).map((item: any) => {
    const {
      title,
      bodyText,
      cta1_ctaLink1: ctaLinkValue,
      cta1_ctaText1: cta1TextValue,
      cta1_ctaAria1: cta1AriaValue,
      cta1_openIn: openInValue,
      legalText_mediaDisclaimer: mediaDisclaimerValue,
      legalText_wltpKey: wltpKeyValue,
      asset,

      // eslint-disable-next-line
    } = item?.fields;

    const { assetType, alternateDescription, imageUrlSmallViewport, imageUrlLargeViewport } =
      asset.fields;

    // eslint-disable-next-line
    const items: any = {
      title,
      bodyText,
      asset: {
        assetType,
        imageUrlSmallViewport: {
          assetValue: imageUrlSmallViewport.path,
        },
        imageUrlLargeViewport: {
          assetValue: imageUrlLargeViewport.path,
        },
        alternateDescription,
      },
      cta1: {
        openIn: openInValue,
        ctaText1: cta1TextValue,
        ctaLink1: ctaLinkValue,
        ctaAria1: cta1AriaValue,
      },
      legalText: {
        wltpKey: wltpKeyValue,
        mediaDisclaimer: mediaDisclaimerValue,
      },
    };

    return items;
  });

  const contentInEditorJsonFormat: EditorJsonContent = {
    theme: fields?.theme,
    gallery: itemsFromGallery,
  };
  return contentInEditorJsonFormat;
};

export default normalizeContent;
